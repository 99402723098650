<template>
    <div
        :class="{'black' : $store.getters.IS_FULLSCREEN_SCHEDULE || $store.getters.IS_FULLSCREEN_CAMERA}"
        style="width:100%;height: auto;background-color: #f8f8f8;overflow: scroll;-webkit-overflow-scrolling: touch;">
        <loader v-show="isLoading"></loader>
        <div class="dashboard--view" :class="{'minified' : isLoading}">
            <div class="main-info--card" v-if="statusScheduleInClass === 3 || statusScheduleInClass === 6">
                <unpayed-card v-if="statusScheduleInClass === 3"
                              type="unpaid"
                              title="Продлите подписку"
                              text="Для полного доступа необходимо продлить тарифный план на сайте в личном кабинете"></unpayed-card>
                <unpayed-card v-if="statusScheduleInClass === 6"
                              type="holiday"
                              title="Каникулы"
                              :text="'До '+profileSchool.holidayDate"></unpayed-card>
            </div>
            <div class="stream-class--card" v-else-if="account != null">
                <schedule-stream-block-card
                    v-on:audio="onChangeAudio(index)"
                    ref="scheduleBlock"
                    v-for="(schoolClass,index) in account.myClasses"
                    :schoolClass="schoolClass"
                    v-bind:key="schoolClass.id"></schedule-stream-block-card>
            </div>
            <div class="stream--view">
                <div class="profile--container">
                    <div class="avatar--container">
                        <div class="outer--gradient">
                            <div class="inner--gradient">
                                <img class="avatar__img" :src="schoolAvatar" draggable="false">
                            </div>
                        </div>
                    </div>
                    <div class="profile-information--container">
                        <p class="name__text">{{profileSchool.name}}</p>
                        <p class="address__text">{{profileSchool.address}}</p>
                    </div>
                </div>
                <div class="slider-player--container video-check">
                    <div class="owl-carousel owl-carousel-cameras"
                         v-show="(!$store.getters.IS_FULLSCREEN_SCHEDULE)">
                        <div class="swiper-slide" v-for="(cam,index1) in cams" v-bind:key="index1">
                          <video-item
                              v-if="index1 === selectedIndex"
                              v-show="(!$store.getters.IS_FULLSCREEN_SCHEDULE)"
                              :video="cam"
                              ref="camsContainer"
                              type="camera"
                              @changeFullscreen="onChangeFullScreen"
                              @changeMute="onChangeAudio(null)">
                          </video-item>
                        </div>
                    </div>
                </div>
                <div class="name-camera--container">
                    <p>{{cameraName}}</p>
                </div>
            </div>
            <div class="slider-icons--container">
            </div>
            <div class="select-camera--container">
                <material-button color="pink-gradient circle" size="md" value="Выбрать камеру" @click.native="showCamerasModal"></material-button>
            </div>
            <cameras-list-modal v-on:select="onSelectCamera"></cameras-list-modal>
        </div>
        <info-pay-modal></info-pay-modal>

    </div>
</template>

<script>
    import 'bootstrap-slider/dist/css/bootstrap-slider.min.css'


    import moment from "moment";
    import {WebsocketFactory} from "@/custom/WebsocketFactory";
    import Loader from "@/components/loader/loader";
    import MaterialButton from "@/components/button/MaterialButton";
    import CamerasListModal from "@/components/modal/CamerasListModal";

    import "tiny-slider/dist/tiny-slider.css"

    import * as EnjoyHint from "xbs-enjoyhint/src/enjoyhint.js"
    import "xbs-enjoyhint/enjoyhint.css"

    import "owl.carousel/dist/owl.carousel"
    import "owl.carousel/dist/assets/owl.carousel.css"
    import ScheduleStreamBlockCard from "../../components/card/ScheduleStreamBlockCard";
    import UnpayedCard from "../../components/card/UnpayedCard";
    import InfoPayModal from "../../components/modal/InfoPayModal";
    import VideoItem from "../../components/video/VideoItem";

    export default {
        name: "ParentView",
        components: {
          VideoItem,
            InfoPayModal,
            UnpayedCard, ScheduleStreamBlockCard, CamerasListModal, MaterialButton, Loader},
        data(){
            return {
                isOpenScheduleContainer :false,
                account: {},
                profileSchool: {},
                cams: [],
                selectedIndex:  null,
                selectedIndexSchedule : null,
                index: 0,
                selectedCamera: {},
                selectedCameraSchedule: {},
                status : 0,
                swiper : null,
                ws : null,
                isLoading : true,
                isMutedCams : true,
                isMutedLesson : true,
                isMayBeAudio: null,
                isMayBeAudioCam :  null,
                timerAudioText: 0,
                timerAudioTextCam: 0,
                keys :  {37: 1, 38: 1, 39: 1, 40: 1},
                supportsPassive : false,
                wheelOpt : false,
                wheelEvent : '',
                actLessonNow: {},
                camerasSchedule:[],
                cameraScheduleSlider: null,
                nextAvailableLesson1: null,
              isFullScreenCamera : false

        }
        },
        watch:{
            selectedIndex:{
                handler(newVal){
                    // this.stop(oldVal)
                    // this.play(newVal)
                    if (this.cams.length > 0){
                        const cam = this.cams[newVal]
                        //@ts-ignore
                        this.isMayBeAudioCam = !!cam.isAudio;
                    }
                },
                immediate:true,
                deep:true
            }
        },
        computed:{
            cameraName(){
                if (this.cams.length > 0){
                    //@ts-ignore
                    return this.cams[this.selectedIndex].name
                }
                return ""
            },
            schoolAvatar(){
                if (this.profileSchool.avatar === null)
                    return ""
                return this.profileSchool.avatar
            },
            isMobile(){
                return screen.width < 992
            },

            statusScheduleInClass(){
                const profileSchool = this.$store.getters.SCHOOL
                if (!this.$store.getters.PAY_STATUS ){
                    return 3
                } else if (profileSchool != null && profileSchool.isHoliday){
                    return 6
                }
                else if (this.isSchoolTimeNotBegin){
                    return 0
                }
                else {
                    return 5
                }
            },
        },
        methods:{
            setSettingsAnimateChatra(){
                //@ts-ignore
                this.isMobileNew = (window.innerWidth < 960)
                window.addEventListener("resize",() => {
                    // @ts-ignore
                    this.isMobileNew = (window.innerWidth < 960)
                })
                //@ts-ignore
                this.enableChatra()
                //@ts-ignore
                if (this.isMobileNew){
                    let exists = setInterval(() => {
                        if (document.getElementById("chatra") != null){
                            clearInterval(exists)

                            // @ts-ignore
                            // eslint-disable-next-line no-undef
                            var tween1 = new TimelineLite();

                            tween1
                                .to('#chatra',1,{autoAlpha: 4})

                            // eslint-disable-next-line no-undef
                            var phone1Controller  = new ScrollMagic.Controller();
                            // eslint-disable-next-line no-undef,no-unused-vars
                            var scene1 = new ScrollMagic.Scene({
                                triggerElement: ".info--container",
                                duration: 100,
                                offset: -200
                            })
                                .setTween(tween1)
                                .addTo(phone1Controller);
                        }
                    },100)
                }
            },
          isIterable(obj) {
            // checks for null and undefined
            if (obj == null) {
              return false;
            }
            return typeof obj[Symbol.iterator] === 'function';
          },
          onChangeFullScreen(isFullscreen ){
            if (isFullscreen && this.isIterable(this.$refs.scheduleBlock)){
              //@ts-ignore
              for (const video of this.$refs.scheduleBlock){
                video.muteSchedule()
              }
            }
            // eslint-disable-next-line no-undef
            var owl = $('.owl-carousel-cameras');
            this.isFullScreenCamera = isFullscreen
            if (this.isFullScreenCamera){
              //@ts-ignore
              owl.trigger("destroy.owl.carousel")
              //@ts-ignore
              document.getElementsByClassName("owl-carousel-cameras")[0].style.display = 'block'
            } else if (!this.isFullScreenCamera) {
              const div = document.createElement("div")
              div.classList.add("slider-icons--container")
              div.style.width = "100%"
              div.style.marginTop = "21px"
              div.style.display = "flex"
              div.style.justifyContent = "center"
              div.style.height = "4px"
              const streamViewDiv = document.getElementsByClassName("select-camera--container")[0]
              //@ts-ignore
              streamViewDiv.parentElement.insertBefore(div,streamViewDiv)
              //@ts-ignore
              owl.owlCarousel({
                items:1,
                dotsContainer : '.slider-icons--container',
                startPosition: this.selectedIndex
              })
            }
          },

            initCarousel(){
                // eslint-disable-next-line no-undef
                var owl = $('.owl-carousel-cameras');
                //@ts-ignore
                owl.owlCarousel({
                    items:1,
                    dotsContainer : '.slider-icons--container'
                })

                //@ts-ignore
                owl.on('changed.owl.carousel', (event) =>  {
                    //@ts-ignore
                    this.selectedIndex = event.item.index
                    this.isMutedCams = true
                    //@ts-ignore
                    const camera = this.cams[this.selectedIndex]
                    this.selectedCamera = {...camera}
                })


                if (this.cams.length > 0){
                    // this.play(0)

                    //@ts-ignore
                    const camera = this.cams[this.selectedIndex]
                    this.selectedCamera = {...camera}
                }
            },
            initScrollPlay(){
                //@ts-ignore
                this.isMobileNew = (window.innerWidth < 960)
                window.addEventListener("resize",() => {
                    // @ts-ignore
                    this.isMobileNew = (window.innerWidth < 960)
                })
                //@ts-ignore
                if (this.isMobileNew){
                    const elements = document.getElementsByClassName("video-check")
                    if (elements.length > 0) {
                        console.log(elements)
                        for (let element of elements) {
                            // eslint-disable-next-line no-unused-vars,no-undef
                            let tween1 = new TimelineLite();



                            // @ts-ignore
                            // eslint-disable-next-line no-undef
                            let phone1Controller = new ScrollMagic.Controller();
                            // eslint-disable-next-line no-undef
                            let scene1 = new ScrollMagic.Scene({
                                triggerElement: element,
                                duration: 350,
                                offset: 30
                            })
                                // .addIndicators()
                                .addTo(phone1Controller);


                            scene1.on('progress',(e) => {
                                console.log(e.currentTarget.triggerElement())
                                console.log("yeah")
                            })
                        }
                    }
                }
            },
            play(index){
                this.index = index
                console.log(this.$refs.camsContainer)
                if (this.$refs.camsContainer == null) return;
                //@ts-ignore
                this.$refs.camsContainer[index].playVideo();
            },
            stop(index){
                console.log(this.$refs.camsContainer)
                if (this.$refs.camsContainer == null) return;
                //@ts-ignore
                this.$refs.camsContainer[index].stopVideo();
            },
            initTutorial(){
                this.supportsPassive = false;
                try {
                    //@ts-ignore
                    window.addEventListener("test", null, Object.defineProperty({}, 'passive', {
                        // eslint-disable-next-line getter-return
                        get: () => { this.supportsPassive = true; }
                    }));
                    // eslint-disable-next-line no-empty
                } catch(e) {}

                this.wheelOpt = this.supportsPassive ? { passive: false } : false;
                this.wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';

                // eslint-disable-next-line no-undef
                var enjoyhint_instance = new EnjoyHint({
                    onEnd: () => {
                        localStorage.setItem("tutorialEnabled","0")
                        this.enableScroll()
                    },
                    onSkip:  () => {
                        localStorage.setItem("tutorialEnabled","0")
                        this.enableScroll()
                    },

                });

                var enjoyhint_script_steps = [
                    {
                        'next .first' : 'Этот блок полностью подчиняется расписанию вашего класса.<br>\n' +
                            'Вы увидите трансляции уроков и будете в курсе учебного плана на день.\n',
                        onBeforeStart:() => {
                            // eslint-disable-next-line no-undef
                            $(".router--container").scrollTo(".first",300)
                        }
                    },
                    {
                        'next .stream--view' : 'В этом блоке показаны стримы общего плана.\n' +
                            'Они работают всегда, вы можете выбирать нужные камеры самостоятельно.\n' +
                            '\n',
                        onBeforeStart:() => {
                            //@ts-ignore
                            // eslint-disable-next-line no-undef
                            $(".router--container").scrollTo(".stream--view",300)
                        }
                    },
                    {
                        'next .chatra--side-bottom' : 'Необходимо достать запись или возникли вопросы? \n' +
                            'Напишите сюда.<br>\n' +
                            'Наши специалисты на 80% состоят из кофе и отвечают мгновенно!\n',
                        'shape' : 'circle',

                    },
                    (this.isMobile) ? {
                        'next .navigation--item:nth-child(2)' : 'Оплаты и истории платежей можно мониторить перейдя в это окно\n' +
                            '\n',
                        'shape' : 'circle',
                        'radius' : 18

                    } : {
                        'next .menu--list-item.pay' : 'Оплаты и истории платежей можно мониторить перейдя в это окно\n' +
                            '\n',
                    },
                    (this.isMobile) ? {
                        'next .navigation--item:nth-child(3)' : 'Что бы задать или изменить персональные данные вам сюда!',
                        'shape' : 'circle',
                        'radius' : 18

                    } :                 {
                        'next .menu--list-item.profile' : 'Что бы задать или изменить персональные данные вам сюда!',
                    },
                ];
                this.disableScroll()
                enjoyhint_instance.set(enjoyhint_script_steps);
                enjoyhint_instance.run();
            },


            showCamerasModal(){
                //@ts-ignore
                this.$modal.show("CamerasListModal",{cameras: this.cams,selectedId : this.selectedCamera?.id})
            },

            onSelectCamera(camera,index){
                this.selectedCamera = {...camera}
                // eslint-disable-next-line no-undef
                var owl = $('.owl-carousel');
                owl.trigger( 'to.owl.carousel', [index] )

                // this.swiper?.slideTo(index)
            },
            async initSchedule(){
                //@ts-ignore
                for (let schoolClass of this.account.myClasses){
                    this.downloadSchedule(this.account.schoolId,schoolClass.id)
                }
            },


            async downloadSchedule(schoolId,schoolClassId){
                const lessons = this.$store.getters.LESSONS(schoolClassId);
                const lessonsStr = JSON.stringify(lessons);
                const crc32 = this.crc32(lessonsStr);
                await this.$store.dispatch("UPDATE_LESSONS",{schoolId : schoolId,classId :schoolClassId,crc32: crc32})
            },
            async onMessage(event){
                // alert("Получены данные " + event.data);
                const data = JSON.parse(event.data)
                switch (data.id) {
                    case 'timeUpdated':
                        this.$store.commit("SET_TIME_NOW",moment(data.time))
                        // await this.initSchedule()
                        this.isLoading = false
                        this.$emit("loaded")
                        break;
                    case 'scheduleUpdated':
                      await this.initSchedule()
                      break;
                }
            },
            onError(event){
                console.error('Socket encountered error: ', event.message, 'Closing socket');
                this.ws.close();
            },
            onOpen(){
                console.log("websocket open")
            },
            onClose(e){
                console.log('Socket is closed. Reconnect will be attempted in 1 second.', e.reason);
                if (e.code != 1000){
                    setTimeout(() => {
                        this.connectSocket();
                    }, 1000);
                }
            },
            sendEnterMessage(ws){
                this.sendSocketMessage(ws,JSON.stringify({
                    "id" : "EnterToTranslation",
                    //@ts-ignore
                    "userId" : this.account.id,
                  "classesId": this.account.myClasses
                }));
            },
            sendSocketMessage(ws,message){
                try{
                    if (ws.readyState === 1){
                        ws.send(message)
                        return true;
                    } else {
                        setTimeout(() => this.sendSocketMessage(ws,message),1000);
                    }
                } catch (e) {
                    console.log(e)
                    return false;
                }
            },
            changeMuteCams(){
                //@ts-ignore
                const cam = this.cams[this.selectedIndex];
                //@ts-ignore
                if (cam.isAudio){
                    this.isMutedCams = !this.isMutedCams
                    // if (!this.isMutedLesson){
                    //     this.isMutedLesson = true
                    // }
                }
              this.mutedCams()
              this.$refs.camsContainer[this.selectedIndex].changeMuted(this.isMutedCams)


                if (!this.isMutedCams){
                    this.onChangeAudio(null)
                }
            },

            preventDefault(e) {
                e.preventDefault();
            },
            preventDefaultForScrollKeys(e) {
                if (this.keys[e.keyCode]) {
                    this.preventDefault(e);
                    return false;
                }
            },
            disableScroll() {
                window.addEventListener('DOMMouseScroll', this.preventDefault, false); // older FF
                window.addEventListener(this.wheelEvent, this.preventDefault, this.wheelOpt); // modern desktop
                window.addEventListener('touchmove', this.preventDefault, this.wheelOpt); // mobile
                window.addEventListener('keydown', this.preventDefaultForScrollKeys, false);
            },
            enableScroll() {
                window.removeEventListener('DOMMouseScroll', this.preventDefault, false);
                window.removeEventListener(this.wheelEvent, this.preventDefault, this.wheelOpt);
                window.removeEventListener('touchmove', this.preventDefault, this.wheelOpt);
                window.removeEventListener('keydown', this.preventDefaultForScrollKeys, false);
            },

            connectSocket(){
                this.ws = WebsocketFactory.make("wss://api.eyekids.ru:8565/profile",this.onOpen,this.onMessage,this.onError,this.onClose);
                this.sendEnterMessage(this.ws);
            },

            onChangeAudio(index = null){
              if (index != null){
                this.isMutedCams = true
                //@ts-ignore
                for(let cam of this.$refs.camsContainer){
                  //@ts-ignore
                  cam.mute()
                }
              }


              console.log("vgr,fgerkl",this.$refs["scheduleBlock"])
              //@ts-ignore
              for (let refFromFor of this.$refs["scheduleBlock"]){
                //@ts-ignore
                if (index == null || refFromFor !== this.$refs["scheduleBlock"][index]){
                  //@ts-ignore
                  refFromFor.muteSchedule()
                }
              }
            },

          mutedCams(){
            const arr = this.$refs.camsContainer;
            if (arr == null || !typeof arr[Symbol.iterator] === 'function'){
              return;
            }
            for(let container of arr){
              container.changeMuted(true)
            }
          },

            makeCRCTable(){
                var c;
                var crcTable = [];
                for(var n =0; n < 256; n++){
                    c = n;
                    for(var k =0; k < 8; k++){
                        c = ((c&1) ? (0xEDB88320 ^ (c >>> 1)) : (c >>> 1));
                    }
                    crcTable[n] = c;
                }
                return crcTable;
            },

            crc32(str) {
                //@ts-ignore
                var crcTable = window.crcTable || (window.crcTable = this.makeCRCTable());
                var crc = 0 ^ (-1);

                for (var i = 0; i < str.length; i++ ) {
                    crc = (crc >>> 8) ^ crcTable[(crc ^ str.charCodeAt(i)) & 0xFF];
                }

                return (crc ^ (-1)) >>> 0;
            },

          async init(){
            try{
              this.isLoading = true
              await this.$store.dispatch("GET_PROFILE")
              this.account = this.$store.getters.PROFILE;
              if (this.account.myClasses.length  === 0){
                await this.$router.push({name: 'CongratulationView'})
              } else {

                //@ts-ignore
                if (this.account.type === 1){
                  document.location.href = '/auth'
                }

                // eslint-disable-next-line no-undef
                Chatra('setIntegrationData', {
                  /* main properties */
                  name: this.account.fullName,
                  email: this.account.email,
                  phone: this.account.phone,
                  userId : this.account.id
                });

                await this.$store.dispatch("GET_SCHOOL",{id : this.account.schoolId})
                this.profileSchool = this.$store.getters.SCHOOL
                //@ts-ignore
                const cams = this.profileSchool.shared;
                //@ts-ignore
                this.cams = [...cams];
                this.selectedIndex = 0
                // this.selectedIndexSchedule = 0
                setTimeout(() => this.initCarousel(),2000)
                if (this.$store.getters.PAY_STATUS){
                  await this.initSchedule();
                  // this.initScrollPlay()
                  // this.initSlider()

                  const tutorialEnabled = localStorage.getItem("tutorialEnabled")

                  if (tutorialEnabled != null && tutorialEnabled === "1"){
                    const interval = setInterval(() =>{
                      if (!this.isLoading){
                        clearInterval(interval)
                        this.initTutorial()
                      }
                    },2000)
                  }

                }

                if (this.ws != null){
                  this.ws.close(1000)
                }
                this.connectSocket()


              }
            } catch (e) {
              console.log(e)
            }
          }

        },
        async mounted(){
          await this.init()

        },

        beforeDestroy(){
            this.ws.close(1000)
        }
    }
</script>

<style scoped lang="scss">
    .dashboard--view{
        width: 375px;
        height: auto;
        margin-left: auto;
        margin-right: auto;
        margin-top: 12px;
        transition: all 0.3s;
        max-height: 2000px;
        &.minified{
            max-height: 0px;
            overflow: hidden;
        }

        @include for-phone-only{
            width: 375px;

        }

        @include for-phone-landscape-up{
            width: 575px;
        }

        @include for-tablet-up{
            width: 768px;
        }

        @include for-desktop-up{
            width: 768px;
        }

        @include for-big-desktop-up{
            width: 768px;
        }

        .unpayed--view{
            background: #FFFFFF;
            box-shadow: 0 4px 8px 0 rgba(0,0,0,0.12);
            border-radius: 4.37px;
            display: flex;
            height: 70px;

            @include for-phone-only{
                width: 375px;

            }

            @include for-phone-landscape-up{
                width: 575px;
            }

            @include for-tablet-up{
                width: 768px;
            }

            @include for-desktop-up{
                width: 768px;

            }

            @include for-big-desktop-up{
                width: 768px;
            }


            .image--container{
                margin-left: 22px;
                margin-top: 15px;
            }

            .text--container{
                margin-top: 21px;
                margin-left: 20px;
                width: 398px;
                .title__text{
                    font-family: Roboto, "sans-serif";
                    font-size: 16px;
                    letter-spacing: 0;
                    color: #CF6868;
                    font-weight: 400;
                }

                .text{
                    font-family: Roboto, "sans-serif";
                    font-size: 14px;
                    letter-spacing: 0;
                    color: black;
                    margin-top: 3px;

                    @include for-mobile-devices{
                        font-size: 12px;
                    }
                }
            }

            .class-name--container{
                margin-left: 152px;
                margin-top: 22px;


                @include for-phone-only{
                    margin-left: 0px;

                }

                @include for-phone-landscape-up{
                    margin-left: 152px;
                }

                @include for-tablet-up{
                    margin-left: 152px;
                }

                @include for-desktop-up{
                    margin-left: 152px;

                }

                @include for-big-desktop-up{
                    margin-left: 152px;
                }

                .class__name{
                    margin-right: 16px;
                    font-family: Roboto, "sans-serif";
                    letter-spacing: 0;
                    font-size: 14px;
                    color: #222222;

                    background: #F4F4F4;
                    width: 98px;
                    height: 26px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 13px;

                    pointer-events: none;
                    -webkit-touch-callout: none; /* iOS Safari */
                    -webkit-user-select: none; /* Safari */
                    -khtml-user-select: none; /* Konqueror HTML */
                    -moz-user-select: none; /* Old versions of Firefox */
                    -ms-user-select: none; /* Internet Explorer/Edge */
                    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
                }
            }
        }

        .schedule-view{
            width: 375px;
            height: auto;
            max-height: 800px;
            background: #FFFFFF;
            box-shadow: 0 4px 8px 0 rgba(0,0,0,0.12);
            border-radius: 4.37px;
            display: flex;
            flex-direction: column;
            transition: all 0.3s;
            position: relative;
            opacity: 1;

            &.minified{
                max-height: 0;
                overflow: hidden;
                transition: all 0.3s;
            }


            @include for-phone-only{
                width: 375px;

            }

            @include for-phone-landscape-up{
                width: 575px;
            }

            @include for-tablet-up{
                width: 768px;
            }

            @include for-desktop-up{
                width: 768px;

            }

            @include for-big-desktop-up{
                width: 768px;
            }
            &.min{

            }

            .schedule--stream-container{
                width: 375px;
                height: 0px;
                min-height: 0px;
                position: relative;
                display: flex;
                transition: all 0.3s;
                overflow: hidden;
                border-radius: 4.37px;

                .no-audio{
                    opacity: 0;
                }


                @include for-phone-only{
                    width: 375px;

                }

                @include for-phone-landscape-up{
                    width: 575px;
                }

                @include for-tablet-up{
                    width: 768px;
                }

                @include for-desktop-up{
                    width: 768px;

                }

                @include for-big-desktop-up{
                    width: 768px;
                }


                .rounded{
                    border-radius: 4.5px 4.5px 0 0;
                    z-index: 1;
                }


                &.show{
                    height: 239px;
                    min-height: 239px;

                    @include for-phone-only{
                        height: 239px;
                        min-height: 239px;
                    }

                    @include for-phone-landscape-up{
                        width: 575px;
                        height: 440px;
                        min-height: 440px;

                    }

                    @include for-tablet-up{
                        height: 588px;
                        min-height: 588px;
                    }

                    @include for-desktop-up{
                        height: 588px;
                        min-height: 588px;

                    }

                    @include for-big-desktop-up{
                        height: 588px;
                        min-height: 588px;
                    }

                    video{
                        height: 239px;

                        @include for-phone-only{
                            width: 375px;

                        }

                        @include for-phone-landscape-up{
                            width: 575px;
                            height: 440px;
                            min-height: 440px;

                        }

                        @include for-tablet-up{
                            width: 768px;
                            height: 588px;
                        }

                        @include for-desktop-up{
                            width: 768px;
                            height: 588px;

                        }

                        @include for-big-desktop-up{
                            width: 768px;
                            height: 588px;
                        }
                    }
                }

                .bottom--container{
                    z-index: 1;
                    position: absolute;
                    right: 0px;
                    bottom: 0px;
                    opacity: 0;
                    transition: all 0.3s;
                    height: 80px;
                    width: 100%;
                    background-image: linear-gradient(-180deg, rgba(0,0,0,0.00) 0%, rgba(0,0,0,0.60) 99%);
                    a{
                        position: absolute;
                        right: 22px;
                        bottom: 20px;
                        cursor: pointer;
                        transition: all 0.3s;
                        z-index: 3;
                        &:hover{
                            transform: scale(1.2);
                        }
                    }
                }

                &:hover{
                    .bottom--container{
                        opacity: 1;
                    }

                    .audio-controls--container{
                        opacity: 1 !important;
                        background-color: transparent;


                        p{
                            background-color: transparent;
                        }
                    }
                }

                video{
                    width: 375px;
                    height: 0px;
                    object-fit: cover;
                }
            }

            .stream-info--container{
                .info--container{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .lesson__name{
                        margin-left: 17px;
                        font-family: Roboto, "sans-serif";
                        letter-spacing: 0;
                        font-size: 17px;
                        color: #333942;
                    }

                    .class__name{
                        margin-right: 16px;
                        font-family: Roboto, "sans-serif";
                        letter-spacing: 0;
                        font-size: 14px;
                        color: #222222;

                        background: #F4F4F4;
                        width: 98px;
                        height: 26px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 13px;
                        pointer-events: none;
                        -webkit-touch-callout: none; /* iOS Safari */
                        -webkit-user-select: none; /* Safari */
                        -khtml-user-select: none; /* Konqueror HTML */
                        -moz-user-select: none; /* Old versions of Firefox */
                        -ms-user-select: none; /* Internet Explorer/Edge */
                        user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
                    }
                }

                .time--container{
                    margin-top: 4.66px;
                    margin-left: 18px;

                    p{
                        font-size: 13px;
                        font-family: Roboto, "sans-serif";
                        letter-spacing: 0;
                        color: #606A7B;
                    }
                }

                .schedule--container{
                    height: auto;
                    max-height: 0;
                    transition: all 0.5s ease-in-out;
                    opacity: 0;
                    pointer-events: none;
                    display: flex;
                    justify-content: space-between;
                    overflow: hidden;

                    .schedule--list{
                        width: 146px;
                        height: auto;
                        margin-top: 16px;
                        margin-left: 22px;

                        .schedule--list-item{
                            display: flex;
                            a{
                                margin-right: 7.5px;
                                span{
                                    font-size: 8px;
                                }
                            }

                            p{
                                font-family: Roboto, "sans-serif";
                                font-size: 14px;
                                letter-spacing: 0px;
                                line-height: 20px;
                                color: black;

                                &.disable{
                                    color: #828282;
                                }
                            }
                        }
                    }

                    .slider--container{
                        height: 106px;
                        width: 2px;
                        margin-top: 13px;
                        display: flex;
                        flex-direction: column;
                        margin-right: 105px;

                        b{
                            margin-left: 16px;
                            font-size: 14px;
                            font-family: Roboto, "sans-serif";
                            letter-spacing: 0;
                            color: #828282;
                        }
                    }


                    .slider--container:first-child{

                    }

                    &.max{
                        max-height: 248px;
                        opacity: 1;
                    }
                }

                .button--container{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    flex-direction: row-reverse;
                    margin-bottom: 18px;
                    margin-right: 20px;
                    margin-left: 18px;

                    p{
                        display: flex;
                        align-items: center;
                        margin-top: 8px;
                        font-family: Roboto, "sans-serif";
                        font-size: 13.1px;
                        color: #606A7B;
                        letter-spacing: 0;
                        line-height: 19.66px;
                        span{
                            margin-right: 4px;
                        }
                    }

                    a{
                        animation: rotating-below 0.5s linear;

                    }

                    a.rotated{
                        transform: rotate(180deg);
                        animation: rotating 0.5s linear;
                    }

                    .btn-open{
                        width: 26px;
                        height: 26px;
                        cursor: pointer;
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        span{
                            font-size: 19px;
                        }

                        &:hover{
                            background-color: #F4F4F4;
                        }
                    }
                }
            }

            .info--container{
                margin-top: 17px;
            }
        }

        .stream--view{
            width: 375px;
            height: 380px;
            border-radius: 4px;
            background: #FFFFFF;
            box-shadow: 0 4px 8px 0 rgba(0,0,0,0.12);
            margin-top: 17px;
            display: flex;
            flex-direction: column;
            @include for-phone-only{
                width: 375px;

            }

            @include for-phone-landscape-up{
                width: 575px;
                height: 580px;
            }

            @include for-tablet-up{
                width: 768px;
                height: 727px;
            }

            @include for-desktop-up{
                width: 768px;
                height: 727px;

            }

            @include for-big-desktop-up{
                width: 768px;
                height: 727px;

            }

            .profile--container{
                height: 50px;
                margin-top: 10px;
                margin-left: 17px;
                display: flex;

                .avatar--container{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .outer--gradient{
                        width: 50px;
                        height: 50px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 50%;
                        background-image: linear-gradient(-180deg, rgba(255,255,255,0.20) 0%, rgba(255,255,255,0.00) 100%);
                        box-shadow: 0 1px 5px 0 rgba(0,0,0,0.20), 0 1px 2px 0 rgba(0,0,0,0.20), inset 0 1px 0 0 rgba(255,255,255,0.16), inset 0 -1px 0 0 rgba(0,0,0,0.05);
                        .inner--gradient{
                            width: 48px;
                            height: 48px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border-radius: 50%;
                            background-image: linear-gradient(-180deg, rgba(255,255,255,0.40) 0%, rgba(255,255,255,0.20) 100%);
                            box-shadow: 0 1px 5px 0 rgba(0,0,0,0.20), 0 1px 2px 0 rgba(0,0,0,0.20), inset 0 1px 0 0 rgba(255,255,255,0.16), inset 0 -1px 0 0 rgba(0,0,0,0.05);
                        }
                    }

                    .avatar__img{
                        width: 46px;
                        height: 46px;
                        border-radius: 50%;
                    }
                }

                .profile-information--container{
                    margin-left: 15px;

                    .name__text{
                        font-family: Roboto, "sans-serif";
                        font-size: 14px;
                        color: #000000;
                        letter-spacing: 0;
                        margin-top: 3px;
                        line-height: 16px;
                    }

                    .address__text{
                        opacity: 0.5;
                        font-family: Roboto, "sans-serif";
                        font-size: 11px;
                        color: #000000;
                        letter-spacing: 0;
                        margin-top: 2px;
                        line-height: 13px;
                        margin-right: 40px;

                    }
                }
            }


            .slider-player--container{
                width: 375px;
                height: 239px;
                margin-top: 11px;
                position: relative;


                @include for-phone-only{
                    width: 375px;
                    height: 239px;
                }

                @include for-phone-landscape-up{
                    width: 575px;
                    height: 440px;

                }

                @include for-tablet-up{
                    width: 768px;
                    height: 587px;
                }

                @include for-desktop-up{
                    width: 768px;
                    height: 587px;

                }

                @include for-big-desktop-up{
                    width: 768px;
                    height: 587px;

                }


                .no-audio{
                    opacity: 0;
                }
            }

            .name-camera--container{
                margin-left: 17px;

                p{
                    font-family: Roboto, "sans-serif";
                    font-size: 17.47px;
                    color: #333942;
                    letter-spacing: 0;
                    text-align: left;
                    line-height: 26.21px;
                    margin-top: 20px;
                }
            }
        }


        .slider-icons--container{
            margin-top: 21px;
            width: 100%;
            display: flex;
            justify-content: center;
            height: 4px;

        }



        .select-camera--container{
            width: 100%;
            display: flex;
            justify-content: center;
            margin-top: 40px;
            margin-bottom: 102px;

            @include for-mobile-devices{
                margin-bottom: 315px;
            }
        }
    }

    .info-icon--container{
        position: absolute;
        right: 364px;
        bottom: 30px;

        @include for-phone-only{
            right: 160px;
            bottom: 20px;
            display: none;
        }

        @include for-phone-landscape-up{
            right: 240px;
            bottom: 30px;
        }
    }


    @keyframes rotating {
        0%   { transform: rotate(0deg); }
        100% { transform: rotate(180deg); }
    }

    @keyframes rotating-below {
        0%   { transform: rotate(180deg); }
        100% { transform: rotate(0deg); }
    }

    .bottom--container{
        position: absolute;
        right: 0px;
        bottom: 0px;
        opacity: 0;
        transition: all 0.3s;
        height: 80px;
        width: 100%;
        z-index: 1;
        background-image: linear-gradient(-180deg, rgba(0,0,0,0.00) 0%, rgba(0,0,0,0.60) 99%);
        a{
            position: absolute;
            right: 22px;
            bottom: 20px;
            cursor: pointer;
            transition: all 0.3s;

            &:hover{
                transform: scale(1.2);
            }
        }
    }

    .slider-player--container:hover{
        .bottom--container{
            opacity: 1;
        }

        .audio-controls--container{
            opacity: 1 !important;
            background-color: transparent;


            p{
                background-color: transparent;
            }
        }
    }

    .audio-controls--container{
        position: absolute;
        left: 17px;
        bottom: 20px;
        opacity: 0;
        border-radius: 50%;
        background-color: rgba(0,0,0,.5);
        z-index: 1;


        p{
            -webkit-touch-callout: none; /* iOS Safari */
            -webkit-user-select: none; /* Safari */
            -khtml-user-select: none; /* Konqueror HTML */
            -moz-user-select: none; /* Old versions of Firefox */
            -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
        }

        @include for-mobile-devices{
            bottom: 15px;

        }

        &.no-audio{
            left: 5px;

            background-color: transparent;
            border-radius: 50%;


            p{
                font-family: Roboto, "sans-serif";
                font-weight: 100;
                font-size: 13px;
                width: 91px;
                height: 28px;
                display: flex;
                align-items: center;
                justify-content: center;
                /*background-color: rgba(0,0,0,.65);*/
                border-radius: 14px;
                letter-spacing: 0.34px;
                transition: all 0.3s;
                color: white;

                -webkit-touch-callout: none; /* iOS Safari */
                -webkit-user-select: none; /* Safari */
                -khtml-user-select: none; /* Konqueror HTML */
                -moz-user-select: none; /* Old versions of Firefox */
                -ms-user-select: none; /* Internet Explorer/Edge */
                user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */


                @include for-mobile-devices{
                    font-size: 11px;

                }
            }
        }

        a{
            cursor: pointer;
            transition: all 0.3s;


            &:hover{
                transform: scale(1.2);
            }

            span{
                color: white;
                font-size: 28px;
            }
        }
    }


    img{
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
    }


    .fake{
        opacity: 0;
        pointer-events: none;
    }

    .image--container{
        img{
            width: 40px;
            height: 40px;
        }
    }

    .info-icon--container{
        img{
            width: 50px;
        }
    }

    .unpayed--view.unpaid{

        @include for-phone-only{
            .image--container{
                margin-left: 12px;
            }

            .text--container{
                margin-left: 10px;
            }
        }
    }


    .slider--icons-schedule{
        margin-top: 21px;
        width: 100%;
        display: flex;
        justify-content: center;
        height: 4px;

        button{
            width: 24px !important;
            height: 4px !important;
            border-radius: 2.5px !important;
            margin-right: 8px;
            background-color: #EBECEF !important;
            opacity: 1 !important;
            border: 0 !important;
            cursor: pointer;
        }

        .tns-nav-active{
            background-color: #F54E5E !important;
        }

    }

    .unpaid{
        display: flex;
        flex-direction: column;
        height: 113px !important;

        .unpaid--user{
            display: flex;
            align-items: center;
            margin-top: 17px;

            .image--container{
                margin-left: 32px;
                margin-top: 0px;
                width: 25px;
                height: 25px;

                img{
                    width: 25px;
                    height: 25px;
                }
            }

            .text--container{
                margin-top: 2px;
                margin-left: 25px;
            }

            .class-name--container{
                margin-top: 2px
            }

        }


        .text-outer--container{
            margin-left: 20px;
            margin-right: 33px;
            margin-top: 8px;

            p{
                font-family: Roboto, "sans-serif";
                font-size: 14px;
                letter-spacing: 0;
                line-height: 18px;
            }
        }
    }

    .black{
      background-color: black;
    }
</style>
